// store/index.js or index.ts
import { createStore } from 'vuex';
import * as signalR from '@microsoft/signalr';
import * as forge from 'node-forge';
const API_URL = process.env.VUE_APP_API_URL;
const SIGNALR_URL = process.env.VUE_APP_SIGNALR_URL;
const API_gongnengtu = process.env.VUE_APP_API_GongNeng;
const API_gameshow = process.env.VUE_APP_API_GameShow;
const API_lunbo = process.env.VUE_APP_API_LunBo;
export default createStore({
    state: {
        isLoggedIn: false,
        showLoginModal: false,
        showRegisterModal: false,
        publicKey: null, // 添加 publicKey 到状态
        GongNengState: {
            state: false,
            data: [],
            error: null
        },
        GameShowState: {
            state: false,
            data: [],
            error: null
        },
        LunBoState: {
            state: false,
            data: [],
            error: null
        },
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true;
        },
        logout(state) {
            state.isLoggedIn = false;
        },
        toggleLoginModal(state) {
            state.showLoginModal = !state.showLoginModal;
        },
        toggleRegisterModal(state) {
            state.showRegisterModal = !state.showRegisterModal;
        },
        setPublicKey(state, publicKey) {
            state.publicKey = publicKey;
        },
        //功能列表
        setGongNengState(state) {
            state.GongNengState.state = true; // 设置为加载中
        },
        setGongNengData(state, data) {
            state.GongNengState.state = false;
            state.GongNengState.data = data;
        },
        setGongNengError(state, error) {
            state.GongNengState.state = false;
            state.GongNengState.error = error;
        },
        //游戏列表
        setGameShowState(state) {
            state.GameShowState.state = true; // 设置为加载中
        },
        setGameShowgData(state, data) {
            state.GameShowState.state = false;
            state.GameShowState.data = data;
        },
        setGameShowError(state, error) {
            state.GameShowState.state = false;
            state.GameShowState.error = error;
        },
        //轮播列表
        setLunBoState(state) {
            state.LunBoState.state = true;
        },
        // 成功获取数据
        setLunBoData(state, data) {
            state.LunBoState.state = false;
            state.LunBoState.data = data;
        },
        // 获取数据时发生错误
        setLunBoError(state, error) {
            state.LunBoState.state = false;
            state.LunBoState.error = error;
        }
    },
    actions: {
        login({ commit }) {
            commit('login');
        },
        logout({ commit }) {
            commit('logout');
        },
        toggleLoginModal({ commit }) {
            commit('toggleLoginModal');
        },
        toggleRegisterModal({ commit }) {
            commit('toggleLoginModal');
            commit('toggleRegisterModal');
        },
        async fetchPublicKey({ commit }) {
            try {
                // 连接到 SignalR 服务器
                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${SIGNALR_URL}`) // 替换为实际的 SignalR Hub URL
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                // 启动连接
                await connection.start();
                console.log("SignalR 连接成功。");
                // 监听从服务器发来的公钥
                connection.on("ReceivePublicKey", (publicKeyPem) => {
                    try {
                        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
                        commit('setPublicKey', publicKey);
                    }
                    catch (error) {
                        console.error("解析公钥 PEM 时出错:", error);
                    }
                });
            }
            catch (error) {
                console.error("SignalR 连接或公钥获取错误:", error);
            }
        },
        //功能列表模块请求
        async fetchGongNeng({ commit }) {
            commit('setGongNengState');
            try {
                // 发起实际的请求来获取数据
                const response = await fetch(`${API_URL}` + `${API_gongnengtu}`);
                if (!response.ok) {
                    throw new Error(`HTTP 错误: ${response.status}`);
                }
                const data = await response.json();
                commit('setGongNengData', data); // 成功时更新数据
            }
            catch (error) {
                commit('setGongNengError', error); // 处理错误
                console.error("获取功能数据时出错:", error);
            }
        },
        //游戏列表模块请求
        async fetchGameShow({ commit }) {
            commit('setGameShowState');
            try {
                // 发起实际的请求来获取数据
                const response = await fetch(`${API_URL}` + `${API_gameshow}`);
                if (!response.ok) {
                    throw new Error(`HTTP 错误: ${response.status}`);
                }
                const data = await response.json();
                commit('setGameShowData', data); // 成功时更新数据
            }
            catch (error) {
                commit('setGameShowError', error); // 处理错误
                console.error("获取功能数据时出错:", error);
            }
        },
        //轮播列表模块请求
        async fetchLunBo({ commit }) {
            commit('setLunBoState');
            try {
                // 发起实际的请求来获取数据
                const response = await fetch(`${API_URL}` + `${API_lunbo}`); // 注意URL格式
                if (!response.ok) {
                    throw new Error(`HTTP 错误: ${response.status}`);
                }
                const data = await response.json();
                commit('setLunBoData', data); // 成功时更新数据
            }
            catch (error) {
                commit('setLunBoError', error); // 处理错误
                console.error("获取轮播数据时出错:", error);
            }
        }
    },
});
