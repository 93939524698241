import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "common-layout" };
import HomeHeader from '@/views_3/HomeHeader.vue';
import HomeMiddle from '@/views_3/HomeMiddle.vue';
import HomeAside from '@/views_3/HomeAside.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Home',
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_el_header = _resolveComponent("el-header");
            const _component_el_aside = _resolveComponent("el-aside");
            const _component_el_main = _resolveComponent("el-main");
            const _component_el_container = _resolveComponent("el-container");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_container, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_el_header, null, {
                            default: _withCtx(() => [
                                _createVNode(HomeHeader)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_el_container, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_el_aside, null, {
                                    default: _withCtx(() => [
                                        _createVNode(HomeAside)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_el_main, null, {
                                    default: _withCtx(() => [
                                        _createVNode(HomeMiddle)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
