import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "app" };
import { defineComponent } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        defineComponent({
            name: 'App',
        });
        // 解决 ERROR ResizeObserver loop completed with undelivered notifications.
        // 重写 ResizeObserver 的构造函数，并在其中定义并调用防抖函数
        window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
            constructor(callback) {
                const debounceDelay = 16; // 防抖延迟时间
                let timer = null;
                const debouncedCallback = (entries, observer) => {
                    if (timer)
                        clearTimeout(timer);
                    timer = setTimeout(() => callback(entries, observer), debounceDelay);
                };
                super(debouncedCallback);
            }
        };
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_view)
            ]));
        };
    }
});
