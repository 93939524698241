import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../public/img/logo.png';
const _hoisted_1 = { class: "header_container" };
const _hoisted_2 = { class: "search-container" };
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import SearchBox from '@/components/SearchBox.vue';
import CebianlanIcon from '@/components/CebianlanIcon.vue';
//搜索框
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomeHeader',
    setup(__props) {
        const searchQuery = ref('');
        const isFocused = ref(false);
        const handleSearch = () => {
            console.log('搜索内容:', searchQuery.value);
        };
        //右侧导航栏
        const activeIndex = ref('0');
        const handleSelect = (index) => {
            activeIndex.value = index;
        };
        //nav栏动态响应
        const collapsed = ref(window.innerWidth < 768); // 用于控制折叠状态的响应式变量
        const isCollapsed = computed(() => collapsed.value);
        const handleResize = () => {
            console.log(window.innerWidth, collapsed.value, isCollapsed.value);
            collapsed.value = window.innerWidth < 768;
        };
        onMounted(() => {
            window.addEventListener('resize', handleResize);
            handleResize(); // 初始化时设置状态
        });
        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });
        return (_ctx, _cache) => {
            const _component_el_col = _resolveComponent("el-col");
            const _component_el_row = _resolveComponent("el-row");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_row, {
                    class: "el-row-container",
                    gutter: 0,
                    justify: 'space-between',
                    align: 'middle'
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_el_col, {
                            class: "elcol1",
                            xs: 6,
                            sm: 6,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createElementVNode("div", { class: "logo_container" }, [
                                    _createElementVNode("svg", {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        height: "100%"
                                    }, [
                                        _createElementVNode("image", {
                                            href: _imports_0,
                                            height: "100%"
                                        })
                                    ])
                                ], -1)
                            ])),
                            _: 1
                        }),
                        _createVNode(_component_el_col, {
                            class: "elcol2",
                            xs: 13,
                            sm: 6,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(SearchBox)
                                ])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_el_col, {
                            class: "elcol3",
                            xs: 3,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }, {
                            default: _withCtx(() => [
                                _createVNode(CebianlanIcon)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
