import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CebianlanIcon',
    setup(__props) {
        const activeIndex = ref('1');
        const handleSelect = (key, keyPath) => {
            console.log(key, keyPath);
        };
        return (_ctx, _cache) => {
            const _component_el_menu_item = _resolveComponent("el-menu-item");
            const _component_el_sub_menu = _resolveComponent("el-sub-menu");
            const _component_el_menu = _resolveComponent("el-menu");
            return (_openBlock(), _createBlock(_component_el_menu, {
                "default-active": activeIndex.value,
                class: "el-menu-demo",
                mode: "horizontal",
                ellipsis: true,
                onSelect: handleSelect
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_el_sub_menu, { index: "1" }, {
                        title: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode("Workspace")
                        ])),
                        default: _withCtx(() => [
                            _createVNode(_component_el_menu_item, { index: "1-1" }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode("item one")
                                ])),
                                _: 1
                            }),
                            _createVNode(_component_el_menu_item, { index: "1-2" }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode("item two")
                                ])),
                                _: 1
                            }),
                            _createVNode(_component_el_menu_item, { index: "1-3" }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("item three")
                                ])),
                                _: 1
                            }),
                            _createVNode(_component_el_sub_menu, { index: "1-4" }, {
                                title: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode("item four")
                                ])),
                                default: _withCtx(() => [
                                    _createVNode(_component_el_menu_item, { index: "1-4-1" }, {
                                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                                            _createTextVNode("item one")
                                        ])),
                                        _: 1
                                    }),
                                    _createVNode(_component_el_menu_item, { index: "1-4-2" }, {
                                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                                            _createTextVNode("item two")
                                        ])),
                                        _: 1
                                    }),
                                    _createVNode(_component_el_menu_item, { index: "1-4-3" }, {
                                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                                            _createTextVNode("item three")
                                        ])),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["default-active"]));
        };
    }
});
