import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container" };
import { ref, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import Loading from '@/components/Loading.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomeMiddle',
    setup(__props) {
        const loadingRef = ref(null);
        const router = useRouter();
        const check_loading = () => {
            const timer = setInterval(() => {
                if (document.readyState === 'complete') {
                    clearInterval(timer);
                    loadingRef.value?.out();
                }
            }, 300);
        };
        onMounted(async () => {
            await nextTick();
            console.log(loadingRef.value);
            check_loading();
            router.beforeEach((to, from, next) => {
                loadingRef.value?.in(() => next());
            });
        });
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_view),
                _createVNode(Loading, {
                    ref_key: "loadingRef",
                    ref: loadingRef,
                    visable: true,
                    onTriggerCheck: check_loading
                }, null, 512)
            ]));
        };
    }
});
