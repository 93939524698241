import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    id: "loading",
    class: "loading-overlay"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'Loading',
    props: {
        visable: { type: Boolean }
    },
    setup(__props, { expose: __expose, emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const loading = {
            in(next) {
                const container = document.getElementById("loading");
                container?.classList.remove("loading_out");
                setTimeout(() => {
                    next();
                    console.log("iiint");
                    emit('triggerCheck');
                }, 1000);
            },
            out() {
                const container = document.getElementById("loading");
                container?.classList.add("loading_out");
                console.log("ooout");
                console.log(container);
            }
        };
        __expose(loading);
        return (_ctx, _cache) => {
            return (_ctx.visable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "loader" }, null, -1)
                ])))
                : _createCommentVNode("", true);
        };
    }
});
