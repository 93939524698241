import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "Aside_container" };
const _hoisted_2 = { class: "Aside_content" };
import KanbanGirl from '@/components/KanbanGirl.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomeAside',
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(KanbanGirl)
                ])
            ]));
        };
    }
});
